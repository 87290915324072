<template>
  <lf-modal :title="$t('STIPS.NEW_DOCUMENT')" :close="() => emit('close')">
    <template #content>
      <form data-cy="create-stip-modal" @submit="submitForm">
        <div class="flex-col p-6">
          <lf-input
            :placeholder="$t('STIPS.STIP_NAME')"
            data-cy="create-stip-name-input"
            name="name"
            :value="stip.name"
          />
          <lf-input
            :placeholder="$t('STIPS.STIP_DESCRIPTION')"
            name="description"
            :value="stip.description"
          />
          <stips-dropdown-categories
            class="mt-4"
            v-model="stip.file_type"
            :placeholder="$t('STIPS.SELECT_FILE_TYPE')"
          />
        </div>
        <div class="border-t border-gray-200 py-6 flex justify-end">
          <modal-buttons
            class="bg-white"
            :on-cancel="() => emit('close')"
            :disabled="isSubmitting"
            save-is-submit
            no-vertical-padding
          />
        </div>
      </form>
    </template>
  </lf-modal>
</template>
<script setup lang="ts">
import { reactive } from "vue";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { usePromiseWrapper } from "@/hooks/common";
import LfModal from "@/components/ui/Modal.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import StipsDropdownCategories from "@/components/deals/StipDropdownCategories.vue";

const { model = { name: "", description: "" } } = defineProps<{
  model?: { name: string; description: string };
}>();

const emit = defineEmits<{
  close: [];
}>();

const stip = reactive({
  name: "",
  description: "",
  file_type: ""
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues: model
});

const { fetchWrapper: submitForm } = usePromiseWrapper(
  handleSubmit(async (values, actions) => {
    await dispatchAction(
      {
        ...values,
        file_type: stip.file_type
      },
      actions,
      "stips/createStip"
    );

    emit("close");
  }),
  {
    onError: () => {
      // noop to catch validation
      return;
    }
  }
);
</script>
